export default class FileUtils {
    static objectToFormData(obj: any, formData?: any, options?: any, pre?: any) {
        // takes a {} object and returns a FormData object
        // https://gist.github.com/ghinda/8442a57f22099bdb2e34
        // https://github.com/therealparmesh/object-to-formdata/blob/master/index.js
        //objectToFormData(
        //    object,
        //    existingFormData, // optional
        //    options, // optional
        //    keyPrefix // optional
        //)

        // whether or not to include array indices in FormData keys
        // defaults to true
        // const options = {
        // indices: true
        //}
        if (this.isFormData(options)) {
            pre = formData;
            formData = options;
            options = null;
        }

        options = options || {};
        options.indices = options.indices || true;
        formData = formData || new FormData();

        if (this.isUndefined(obj) || this.isNull(obj)) {
            return formData;
        } else if (this.isArray(obj)) {
            obj.forEach((value: any, index: any) => {
                // FILES do not appear to work with an index anymore on core
                let key = pre;
                if (!this.isFile(value)) {
                    key = pre + "[" + (options.indices ? index : "") + "]";
                }
                this.objectToFormData(value, formData, options, key);
            });
        } else if (this.isDate(obj)) {
            formData.append(pre, obj.toISOString());
        } else if (this.isObject(obj) && !this.isFile(obj) && !this.isBlob(obj)) {
            Object.keys(obj).forEach((prop) => {
                const value = obj[prop];
                if (this.isArray(value)) {
                    while (prop.length > 2 && prop.lastIndexOf("[]") === prop.length - 2) {
                        prop = prop.substring(0, prop.length - 2);
                    }
                }

                const key = pre ? pre + "." + prop : prop;

                this.objectToFormData(value, formData, options, key);
            });
        } else if (this.isBlob(obj)) {
            formData.append(pre, obj, obj.name);
        } else {
            formData.append(pre, obj);
        }

        return formData;
    }

    static isUndefined(value: any) {
        return value === undefined;
    }
    static isNull(value: any) {
        return value === null;
    }
    static isObject(value: any) {
        return value === Object(value);
    }
    static isArray(value: any) {
        return Array.isArray(value);
    }
    static isDate(value: any) {
        return value instanceof Date;
    }
    static isBlob(value: any) {
        return (
            value &&
            typeof value.size === "number" &&
            typeof value.type === "string" &&
            typeof value.slice === "function"
        );
    }
    static isFile(value: any) {
        return (
            this.isBlob(value) &&
            (typeof value.lastModifiedDate === "object" || typeof value.lastModified === "number") &&
            typeof value.name === "string"
        );
    }
    static isFormData(value: any) {
        return value instanceof FormData;
    }
}
