










































import { Component, Vue } from "vue-property-decorator";
import { required, email } from "@/common/validators/rules";

import { NotificationsApi } from "@/store/notifications-api";

@Component({ components: {} })
export default class FixtureSubscriptionSignup extends Vue {
    notificationsStore = new NotificationsApi();
    validators = { required, email };
    loading = false;
    email = "";
    complete = false;
    fail = false;
    dialogOpen = false;

    open() {
        this.email = "";
        this.complete = false;
        this.fail = false;
        this.loading = false;
        this.dialogOpen = true;
    }

    signup() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        this.loading = true;
        this.dialogOpen = true;
        this.notificationsStore
            .signup({ email: this.email })
            .then(() => (this.complete = true))
            .then(() => (this.loading = false))
            .catch(() => {
                this.loading = false;
                this.fail = true;
            });
    }
}
