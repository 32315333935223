

















import { Component, Vue } from "vue-property-decorator";
import { NotificationsApi } from "@/store/notifications-api";

@Component({ components: {} })
export default class FixtureSubscriptionVerify extends Vue {
    notificationsStore = new NotificationsApi();
    loading = false;
    fail = false;
    dialogOpen = false;

    open(email: string, token: string) {
        this.loading = true;
        this.dialogOpen = true;
        this.notificationsStore
            .verify({ email: email, token: token })
            .then(() => (this.loading = false))
            .catch(() => {
                this.loading = false;
                this.fail = true;
            });
    }
}
