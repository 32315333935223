export const required = () => (v: any) => (Array.isArray(v) ? v.length > 0 : v && !!v.toString().trim()) || "Required";
export const dateAfter = (otherDate: Date, message: string) => (v: any) =>
    (otherDate && new Date(v) > new Date(otherDate)) || message;
export const dateBefore = (otherDate: Date, message: string) => (v: any) =>
    (otherDate && new Date(v) < new Date(otherDate)) || message;
export const maxLength = (length: number) => (v: string) =>
    typeof v !== "string" || (v || "").length <= length || `Max length of ${length}`;
export const minLength = (length: number) => (v: string) => (v && v.length >= length) || `Min length of ${length}`;

// Regex - <X>@<Y>.<Z>
// Dot or comma or whitespace should NOT be the last character
// Shouldn't be surrounded by angular brackets
export const email = () => (v: any) => !v || /^[^<]\S+@\S+\.\S+[^.^,^>]$/.test(v.trim()) || "Must be a valid email";

export const multiEmail = (maxEmails?: number) => (v: string) => {
    const emails = (v || "").split(",").map((p) => p.trim());

    if (maxEmails && emails.length > maxEmails) {
        return `Only ${maxEmails} emails are allowed`;
    }

    return emails.every((v) => !v || email()(v) === true) || "Must be comma separated list of valid emails";
};
export const numeric = () => (v: string) => /\d+/.test(v) || "Must include numbers (0-9)";
export const alphaLower = () => (v: string) => /[a-z]/.test(v) || "Must include lowercase letter (a-z)";
export const alphaUpper = () => (v: string) => /[A-Z]/.test(v) || "Must include uppercase letter (A-Z)";
export const equal = (other: string, message: string) => (v: string) => other === v || message;
export const requiredIf = (other: boolean, message?: string) => (v: any) =>
    !other || (Array.isArray(v) ? v.length > 0 : !!v) || message || "Required";
