






































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { FixturesApi, Fixture } from "@/store/fixtures-api";
import formatTime from "@/common/filters/format-time";
import formatDate from "@/common/filters/format-date";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";

@Component({
    components: { CDeleteConfirm },
    filters: { formatTime, formatDate },
})
export default class FixtureItem extends Vue {
    @Prop() fixture!: Fixture;
    @Prop({ type: Boolean, default: false }) canEdit: boolean | undefined;
    fixturesStore = new FixturesApi();
    active = false;

    @Emit()
    edit() {
        return this.fixture;
    }

    @Emit("deleted")
    deleteConfirmed() {
        return this.fixturesStore.delete({ fixtureId: this.fixture.id });
    }

    played(fixture: Fixture) {
        return new Date(fixture.dateTime) < new Date() || (fixture.homeScore >= 0 && fixture.opponentScore >= 0);
    }

    isWin(fixture: Fixture) {
        return fixture.homeScore >= fixture.opponentScore;
    }
}
