import { axios } from "./axios";
import { ValueText } from "@/common/models/interfaces";
import FileUtils from "@/common/utils/fileUtils";

export class TeamsApi {
    getAll({ teamCategory }: { teamCategory?: string }) {
        return axios.get<Team[]>("/teams/all", { params: { teamCategory } });
    }

    getPrevious() {
        return axios.get<Team[]>("/teams/previous");
    }

    get({ teamId }: { teamId: number }) {
        if (!teamId) {
            return Promise.resolve({ data: {} as Team });
        }
        return axios.get<Team>(`/teams/${teamId}`);
    }

    save({ team }: { team: Team }) {
        const formData = FileUtils.objectToFormData(team);
        if (!!team.id && team.id > -1) {
            return axios.put("/teams", formData);
        } else {
            return axios.post("/teams", formData);
        }
    }

    delete({ teamId }: { teamId?: number }) {
        return axios.delete(`/teams/${teamId}`);
    }

    getOptions() {
        return axios.get<TeamOptions>("/teams/options");
    }
}

export interface Team {
    id: number;
    name: string;
    alias: string;
    description: string;
    teamCategoryId: number;
    seasonStartYear: string;
    externalLeagueUri: string;
    imageUpload: Blob;
    coaches: string[];
    assistants: string[];
    managers: string[];
    contributors: string[];
    officials: Official[];
    mediaUri: string;
    isNonCompetitiveLeague: boolean;
}

export interface TeamOptions {
    venues: ValueText[];
    officials: ValueText[];
    teamCategories: ValueText[];
    seasons: ValueText[];
}

export interface Official {
    firstName: string;
    lastName: string;
    officialType: number;
    officialTypeString: string;
    imageUri: string;
    email: string;
}
