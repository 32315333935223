





























































































































import { Component, Vue } from "vue-property-decorator";
import { FixturesApi, CalendarEvent } from "@/store/fixtures-api";
import { VenuesApi, Venue } from "@/store/venues-api";
import FixtureItem from "./FixtureItem.vue";
import FixtureSubscriptionSignup from "./FixtureSubscriptionSignup.vue";
import FixtureSubscriptionVerify from "./FixtureSubscriptionVerify.vue";
import { TeamsApi, Team } from "@/store/teams-api";
import formatDate from "@/common/filters/format-date";
import formatTime from "@/common/filters/format-time";

@Component({
    components: {
        FixtureItem,
        FixtureSubscriptionSignup,
        FixtureSubscriptionVerify,
    },
    filters: { formatDate, formatTime },
})
export default class Calendar extends Vue {
    fixtureStore = new FixturesApi();
    venuesStore = new VenuesApi();
    teamsStore = new TeamsApi();
    events: CalendarEvent[] = [];
    venues: Venue[] = [];
    teams: Team[] = [];
    selectedOpen = false;
    selectedEvent: CalendarEvent = {} as CalendarEvent;
    selectedElement: EventTarget | null = null;
    loading = false;
    focus = "";
    range = 4;
    venueIds: number[] = [];
    teamIds: number[] = [];
    typeToLabel = {
        month: "Month",
        week: "Week",
        day: "Day",
    };
    type = "week";
    eventTypes = [
        { text: "Games & Training", value: "both" },
        { text: "Games", value: "games" },
        { text: "Training", value: "training" },
    ];
    eventType = "both";
    weekdays: number[] = [1, 2, 3, 4, 5, 6, 0];

    get title() {
        return (this.$refs.calendar as any).title;
    }

    get isTraining() {
        return this.selectedEvent.eventType == 1;
    }

    get gameTitle() {
        return this.selectedEvent.home
            ? `${this.selectedEvent.name} v ${this.selectedEvent.opponent}`
            : `${this.selectedEvent.opponent} v ${this.selectedEvent.name}`;
    }

    mounted() {
        this.venuesStore.getAll(true).then((res) => (this.venues = res.data));

        this.teamsStore.getAll({ teamCategory: "" }).then((res) => (this.teams = res.data));

        if (this.$route.query.email && this.$route.query.token) {
            (this.$refs.verify as any).open(this.$route.query.email, this.$route.query.token);
        }
    }

    getEvents({ start, end }: { start: any; end: any }) {
        this.loading = true;
        this.fixtureStore
            .getCalendarEvents({
                start: start.date,
                end: end.date,
                venueIds: this.venueIds,
                teamIds: this.teamIds,
                eventType: this.eventType,
            })
            .then((res) => (this.events = res.data))
            .then(() => (this.loading = false));
    }

    setToday() {
        this.focus = "";
    }

    prev() {
        (this.$refs.calendar as any).prev();
    }

    next() {
        (this.$refs.calendar as any).next();
    }

    filtersChanged() {
        this.getEvents({
            start: (this.$refs.calendar as any).lastStart,
            end: (this.$refs.calendar as any).lastEnd,
        });
    }

    selectEvent({ nativeEvent, event }: { nativeEvent: MouseEvent; event: CalendarEvent }) {
        const open = () => {
            this.selectedEvent = event;
            this.selectedElement = nativeEvent.target;
            setTimeout(() => {
                this.selectedOpen = true;
            }, 10);
        };

        if (this.selectedOpen) {
            this.selectedOpen = false;
            setTimeout(open, 10);
        } else {
            open();
        }

        nativeEvent.stopPropagation();
    }

    getEventColor(event: CalendarEvent) {
        return event.eventType == 1 ? "orange" : "blue";
    }
}
