import { axios } from "./axios";

export class NotificationsApi {
    signup({ email }: { email: string }) {
        return axios.post<NotificationOptions>("/notifications/signup", { email });
    }

    verify({ email, token }: { email: string; token: string }) {
        return axios.post<NotificationOptions>("/notifications/signup/verify", {
            email,
            token,
        });
    }
}

export interface NotificationOptions {
    email: string;
    token: string;
}
