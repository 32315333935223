import { axios } from "./axios";

export class VenuesApi {
    getAll(home?: boolean) {
        return axios.get<Venue[]>("/venues", {
            params: {
                home: home,
            },
        });
    }

    save({ venue }: { venue: Venue }) {
        if (!!venue.id && venue.id > -1) {
            return axios.put("/venues", venue);
        } else {
            return axios.post("/venues", venue);
        }
    }

    delete({ venueId }: { venueId: number }) {
        return axios.delete(`/venues/${venueId}`);
    }
}

export interface Venue {
    id: number;
    name: string;
    formatted_address: string;
    isHome: boolean;
    lng: number;
    lat: number;
    url: string;
    fixtures: number;
    opponents: number;
}
