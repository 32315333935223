


















import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { VProgressLinear, VForm, VDivider } from "vuetify/lib";

@Component({ components: { VProgressLinear, VForm, VDivider } })
export default class CDeleteConfirm extends Vue {
    @Prop({ type: Function }) title!: any;

    dialog = false;
    item: any = {} as any;

    get hasActivator() {
        return !!this.$scopedSlots.activator;
    }

    get titleText() {
        return this.title(this.item);
    }

    open(item: any) {
        this.item = item;
        this.dialog = true;
    }

    close() {
        this.dialog = false;
    }

    @Emit()
    confirmed() {
        this.dialog = false;
        return this.item;
    }
}
