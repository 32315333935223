import { axios } from "./axios";
import { AxiosResponse } from "axios";
import { ValueText } from "@/common/models/interfaces";

export class FixturesApi {
    getAll({ teamId, range }: { teamId?: number; range?: number }) {
        return axios.get<Fixture[]>("/fixtures", {
            params: { teamId, range },
        });
    }

    getCalendarEvents({
        start,
        end,
        venueIds,
        teamIds,
        eventType,
    }: {
        start: Date;
        end: Date;
        venueIds: number[];
        teamIds: number[];
        eventType: string;
    }) {
        return axios.get<CalendarEvent[]>("/fixtures/calendar", {
            params: { start, end, venueIds, teamIds, eventType },
        });
    }

    get({ fixtureId }: { fixtureId?: number }) {
        if (!fixtureId) {
            return Promise.resolve<AxiosResponse<Fixture>>({
                data: {
                    date: new Date().toISOString().substring(0, 10),
                    minute: 0,
                    home: true,
                } as Fixture,
            } as AxiosResponse);
        }
        return axios.get<Fixture>(`/fixtures/${fixtureId}`);
    }

    save({ fixture }: { fixture: Fixture }) {
        if (fixture.id) {
            return axios.put("/fixtures", fixture);
        } else {
            return axios.post("/fixtures", fixture);
        }
    }

    delete({ fixtureId }: { fixtureId?: number }) {
        return axios.delete(`/fixtures/${fixtureId}`);
    }

    getOptions() {
        return axios.get<FixtureOptions>("/fixtures/options");
    }
}

export interface Fixture {
    id?: number;
    teamId: number;
    address: string;
    team: string;
    opponent: string;
    teamUri: string;
    opponentUri: string;
    dateTime: string;
    competition: string;
    date: string;
    hour: string;
    minute: number;
    time: string;
    homeScore: number;
    opponentScore: number;
    competitionId: number;
    venueId: number;
    opponentId: number;
    home: boolean;
    contactName: string;
    contactNumber: string;
    referee: string;
    kit: string;
    notes: string;
    mapUri: string;
    competitive: false;
}

export interface CalendarEvent {
    address: string;
    name: string;
    opponent: string;
    teamUri: string;
    opponentUri: string;
    home: boolean;
    start: Date;
    end: Date;
    timed: boolean;
    eventType: 1 | 2;
}

export interface VenueOption {
    value: any;
    text: string;
    isHome: boolean;
    opponentIds: number[];
}

export interface FixtureOptions {
    competitions: ValueText[];
    venues: VenueOption[];
    opponents: ValueText[];
    hours: ValueText[];
    minutes: ValueText[];
}
